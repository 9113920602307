.container {
   width: 100%;
   background: rgb(2,0,36);
   background: linear-gradient(90deg, rgba(2,0,36,1) 0%, #1498dd 0%, rgba(28,28,28,1) 56%);
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 70px 40px;
   margin: 50px 0;
   border-radius: 20px;
}
.container div {
   text-align: center;
}

.container div h1 {
   font-size: 50px;
   font-weight: bolder;
}



.counter_wrapper {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   justify-content: space-between;
   flex-basis: 70%;
}

@media (max-width: 700px) {
   .container {
      flex-direction: column;
      text-align: center;
      padding: 30px;
      width: 90%;
      margin: auto;
   }

   .title {
      font-size: 20px;
   }


   .counter_wrapper {
      justify-content: space-between;
      margin-top: 20px;
   }

   .counter_wrapper div {
      flex-basis: 50%;
      margin-top: 20px;

   }

   .counter_wrapper div h1 {
      font-size: 35px;
   }

}