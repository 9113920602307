.main {
  max-width: 700px;
  margin: 5% auto;
  font-family: Arial, Helvetica, sans-serif;
  /* background-color: black; */
}

@media (max-width: 700px) {
   .main {
      width: 90%;
   }
}

.main h1, .main h2 {
   margin: 30px 0px;
   /* font-size: 40PX; */
   /* font-weight: 900; */
}
.main h1,
.main h2,
.main h3,
.main h4,
.main h5 {
  color: gree;
  margin-bottom: 20px;
}

.main p,
.main li {
  color: gray;
  margin-bottom: 20px;
  font-size: 15px !important;

}

.main ul li {
   list-style: disc !important;
   list-style-type: disc !important;
   list-style-position: outside !important;
   margin-left: 30px;
}

.main b {
   font-weight: 900;
   color: #bbb;;
}

.main a {
   color: #1498dd;
   font-weight: bold;
  font-size: 15px !important;

}