.main {
   display: flex;
   justify-content: space-between;
   margin-top: 2.5rem;
}

.main article {
   flex-basis: 65%;
   padding: 10px;
   margin: 10px;
}

.main aside {
   flex-basis: 30%;
   padding: 10px;
   margin: 10px
}

.post_wrapper {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   margin-top: 1rem;
   
}
.post_list {
   flex-basis: 48%;
   margin-bottom: 3rem;
}

.post_list img {
   width: 100%;
   height: 250px;
   object-fit: cover;
   border-radius: 10px;
}

.description {
   margin-top: 20px;
   margin-bottom: 5px;
}

.date {
   color: #1498dd;
   font-size: 14px;
   font-weight: bold;
   margin-top: 5px;
}


/* Suggested */
.suggested_wrapper {
   margin-top: 1rem;
}
.suggested_container {
   display: flex;
   margin-bottom: 2.5rem;
}

.suggested_container img {
   width: 70px;
   height: 70px;
   border-radius: 10px;
   margin-right: 1.5rem;
}

.subscribe {
   width: 100%;
   background-color: #1498dd;
   border-radius: 10px;
   padding: 45px 20px;
   /* height: 25vh; */
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   align-items: flex-start;
}


.subscribe button {
   padding: 17px 40px;
   background-color: black;
   color: white;
   border-radius: 30px;
   cursor: pointer;
}

.subscribe p {
   margin: 20px 0px
}

@media (max-width: 700px) {

   .main {
      flex-direction: column;
   }

   .post_wrapper {
      flex-direction: column;
   }

}