.about_background_image {
   position: relative;
   width: 100%;
   height: 35vh;
   background-color: #1498dd94;
   background: url("https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2") no-repeat;
   background-size: cover;
   background-attachment: fixed;

}


.contact_container {
   position: relative;
   width: 100%;
   height: 35vh;
   background-color: #1498dd94;
   background: url("https://images.pexels.com/photos/45842/clasped-hands-comfort-hands-people-45842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2") no-repeat;
   background-size: cover;
   background-attachment: fixed;

}

.rider_background_image {
   position: relative;
   width: 100%;
   height: 35vh;
   background-color: #1498dd94;
   background: url("https://images.pexels.com/photos/604285/pexels-photo-604285.jpeg?auto=compress&cs=tinysrgb&w=800") no-repeat;
   background-size: cover;
   background-attachment: fixed;

}
.privacy_background_image {
   position: relative;
   width: 100%;
   height: 35vh;
   background-color: #1498dd94;
   background: url("https://images.pexels.com/photos/164425/pexels-photo-164425.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2") no-repeat;
   background-size: cover;
   background-attachment: fixed;

}
.terms_background_image {
   position: relative;
   width: 100%;
   height: 35vh;
   /* background-color: #1498dd94; */
   background: url("https://images.pexels.com/photos/7821937/pexels-photo-7821937.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2") no-repeat;
   /* background-position: center; */
   background-size: cover;
   background-attachment: fixed;

}
.wrapper {
   position: absolute;
   bottom: 0;
   left: 0;
   background-color: #1498dd94;
   width: 100%;
   height: 100%;
   display: flex;
   /* align-items: center; */
   justify-content: center;
   flex-direction: column;
   padding: 46px 30px;

}

.conatiner h1 {
   font-size: 50px;
   margin-bottom: 20px;
}

.download_ {
   margin-top: 20px;
   display: flex;
 }
 .download_ img{
   width: 200px;
   height: 70px;
   /* border-radius: 20px; */
   margin-right: 20px;
 }
 


@media (max-width: 700px) {

   .conatiner {
      height: 40vh;
    
   }

   .wrapper {
      display: flex;
      /* align-items: center; */
      /* justify-content: center; */
   }
   .conatiner h1 {
      font-size: 25px;
   }

   .conatiner p {
      color: #ffffff94;
   }


   .download_ {
      justify-content: center;
      align-items: center;
   }
   .download_ img{
      width: 150px;
      height: auto;
      /* margin: 10px; */
    }

}

