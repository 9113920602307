.main {
   display: flex;
   justify-content: space-between;
   justify-content: space-between;
   padding: 20px;
}

.left_section {
   flex-basis: 60%;
}

.right_section {
   flex-basis: 30%;
}

.main h2 {
   margin-bottom: 15px;
}

.main p {
   color: #bfbfbf;
}

.title_seciton {
   padding: 20px;
   background-color: #414141;
   border-radius: 30px;
   cursor: pointer;
   display: flex;
   justify-content: space-between;
   align-items: center;
   transition: all ease .4s;
}

.description_section {
   margin-left: 20px;
   overflow: hidden;
   animation: increasec_height ease 1s
}


@keyframes increasec_height {
   from {
      height: 0px
   }
   to {
      height: 70px;
   }
}
.description_section p {
   padding: 10px;
}

.title_seciton i {
   color: white;
}

.list_wrapper {
   margin: 40px 0px;
}



.subscribe {
   width: 100%;
   background-color: #1498dd;
   border-radius: 10px;
   padding: 45px 20px;
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   align-items: flex-start;
}


.subscribe button {
   padding: 17px 40px;
   background-color: black;
   color: white;
   border-radius: 30px;
   cursor: pointer;
}

.subscribe p {
   margin: 20px 0px;
   color: white
}


@media (max-width: 700px) {
   .main {
      flex-direction: column;
   }

}