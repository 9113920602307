.main {
  margin: 4% auto;
  max-width: 80%;
}

.left_container {
  flex-basis: 35%;
  padding: 20px;
  margin: 20px;
}

.right_container {
  flex-basis: 60%;
  padding: 20px;
  margin: 20px;
  background-color: rgb(33, 33, 33);
}

/* Form section */
.main h2 {
  margin-bottom: 15px;
  font-weight: 900;
}

.main p {
  color: #bfbfbf;
}
.form_wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input_wrapper {
  flex-basis: 45%;
  margin-bottom: 5%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iput_container {
   display: flex;
   justify-content: space-between;
   align-items: center;
  width: 100%;
  /* padding:10px; */
  background-color: unset;
  /* border: 1px solid rgb(65, 65, 65); */
  color: white;
  margin-top: 10px;
}

.input_wrapper input[type="text"], .input_wrapper select {
  width: 100%;
  padding: 20px;
  background-color: unset;
  border: 1px solid #ddd;
  color: white;
  margin-top: 10px;
  /* border-radius: 30px; */
}

.input_wrapper select {
   padding-left: unset;
  padding: 20px; 

}

label {
  line-height: 2.4;

}

.input_wrapper input[type="file"] {
display: none;
}






.submit_btn button {
  padding: 14px 20px;
  background-color: #1498dd;
  color: white;
  border-radius: 20px;
  margin-top: 20px;
}

@media (max-width: 700px) {
  .main {
    flex-direction: column;
  }

  .form_wrapper {
    flex-direction: column;
  }

  .input_wrapper {
    margin-bottom: 2rem;
  }
}

.error {
  color: red !important;
  font-weight: bold;
  margin-top: 10px;
}


.upload_btn {
  width: 120px;
   display: flex;
   align-items: center;
   padding: 4px 20px;
   color: white !important;
   border-radius: 20px;
   border: 1px solid gray;
   margin: 15px 0px;
   cursor: pointer;
}

.upload_btn svg {
   margin-right: 10px;
}
.status_txt {
   font-size: 14px;
   margin: 10px 0px;
   font-weight: 900;
   text-transform: capitalize;
}

.upload_btn span {
   font-size: 14px;
   font-weight: 900 !important;
}

.preview_wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.529);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prv_section {
  width: 400px;
  height: 500px;
  background-color: black;
  padding: 20px;
  border: 1px solid #1498dd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 700px) {
  .prv_section {
    width: 90%;
  }
}

.prv_section img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.prv_section button {
  width: 100%;
  padding: 10px;
  background-color: #1498dd;
  font-weight: bold;
  color: white;
  margin-top: 20px
}

.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.head svg {
  font-size: 30px;
}

.iput_container a {
  color: #1498dd !important;
  font-weight: 900;
  text-decoration: underline;
}

.account_ p{
margin: 10px 0px;}


.account_ button {
  color: white;
  background-color: #ca4bd4;
  padding: 10px 20px;
  font-weight: bolder;
}

.error_reasons {
  border: 1px solid rgba(255, 0, 0, 0.451);
  background-color: rgba(216, 77, 77, 0.441);
  padding: 0px 10px;
}

.verified_container {
  width: 100%;
  height: 100%;
  background-color: #000000d4;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verified_wrapper {
  width: 500px;
  background-color: rgb(0, 0, 0);
  padding: 10px;
}


@media (max-width: 700px) {
  .verified_wrapper {
    width: 90%;
  }
}

.logout_container button{
  margin: 20px auto;
  display: block;
    display: flex;
    align-items: center;
    color: white;
}

.logout_container svg {
  margin-right: 5px;
}

