.section .top_styles {
  text-align: center;
  line-height: 2;
  padding: 20px;
}

.top_styles h1 {
  font-size: 30px;
  color: #ffffff;
}

.top_styles p {
  color: #bfbfbf;
}

.main {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
}

.left_section {
  flex-basis: 35%;
}

.right_section {
  flex-basis: 35%;
}

.middle_section img {
  width: 350px;
}

.dot_container {
  flex-basis: 30%;
}
.dot_wrapper {
  width: 30px;
  height: 30px;
  background-color: #bfbfbf58;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0px 20px;
}

.round_dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1498dd;
}

.left_section .text_wrapper {
  text-align: right;
}

.text_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 34px 0;
}

.text_wrapper p {
  color: #bfbfbf;
  margin: 10px 0px;
}

.text_wrapper h3 {
  color: #1498dd;
}

@media (max-width: 700px) {
  .main {
    width: 100%;
    flex-direction: column;
    padding: unset;
    line-height: unset;
  }

  .left_section div {
    flex-direction: row-reverse;
  }

  .left_section .text_wrapper {
    text-align: left;
  }

  .text_wrapper p {
    width: 90%;
  }

  .section .top_styles {
    line-height: 1.4;
  }

  .section .top_styles * {
    margin-bottom: 20px;
  }

  .top_styles h1 {
    font-size: 25px;
  }
}
