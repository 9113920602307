.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    #1498dd 0%,
    rgba(28, 28, 28, 1) 56%
  );
  padding: 70px 40px;
  margin: 50px 0;
  margin-bottom: 5rem;
  border-radius: 20px;
}

.left_wrapper {
  display: flex;
  flex-basis: 50%;
}

.big_icon i {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(221, 221, 221, 0.349);
  border-radius: 50%;
  margin-right: 20px;
  color: white;
  font-size: 30px;
}

.left_wrapper h2 {
  margin-bottom: 20px;
}
.input_section {
  flex-basis: 50%;
}
.input_wrapper {
  position: relative;
  width: 70%;
  float: right;
}
.input_section input {
  padding: 15px;
  width: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
}

.submit_btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.submit_btn svg {
  font-size: 25px;
  color: #1498dd;
}

@media (max-width: 700px) {
  .container {
    flex-direction: column;
  }

  .left_wrapper {
   flex-direction: column;
   flex-basis: 100%;
  }

  .input_wrapper {
   width: 100% !important;
  }

  .input_section {
   width: 100% !important;
   margin-top: 25px;
  }

  .big_icon i {
   width: 90px;
   height: 90px;
   margin-bottom: 30px;

  }
}
