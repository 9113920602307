.main {
  display: flex;
  align-items: center;
  padding: 50px;
  justify-content: space-around;
}

.about_main {
  align-items: flex-start;
}

.data {
  flex-basis: 50%;
}

.about_data {
  flex-basis: 70%;
}

.image_wrapper {
  flex-basis: 50%;
}

.data h1 {
  color: #1498dd;
  margin-bottom: 20px;
  font-weight: bolder;
  font-size: 40px;
}

.data p {
  margin-bottom: 17px;
  line-height: 2;
  color: #bfbfbf;
}

.flex_btm_box {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.flex_btm_box i {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
  border-radius: 50%;
  margin-bottom: 30px;
  color: #1498dd;
  font-size: 30px;
}

.icon_wrapper i {
  width: 55px !important;
  height: 55px !important;
  background-color: rgba(255, 255, 255, 0.389);
  border-radius: 50%;
  color: white;
  font-size: 20px !important;
}

.flex_btm_box h3 {
  color: #1498dd;
  margin-bottom: 10px;
}

.box_1 {
  width: 40%;
}

.about_data .box_1 {
  flex-basis: 80%;
}

.about_data .box_1 p {
  font-size: 14px;
}

.image_wrapper {
  display: flex;
  justify-content: flex-end;
}

.image_wrapper img {
  width: 250px;
  height: auto;
  float: right;
}

.logo_large {
  width: 400px !important;
  height: auto;
  border-radius: 20px;
  margin-bottom: 10px;
  /* animation: running_bird  2s infinite linear; */
}

/* Col 2 styles */
.col_formart {
  flex-direction: column;
}

.row_format {
  display: flex;
  width: 100% !important;
  margin-bottom: 25px;
}

.row_format i {
  width: 50px;
  height: 40px;
  font-size: 14px;
  margin-right: 25px;
}

.image_wrapper2 {
  background: url("https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/IMG-bg.png")
    no-repeat;
}

.image_wrapper2 img {
  width: 85%;
  height: 40vh;
  border-radius: 20px;
}

@media (max-width: 700px) {
  .main {
    flex-direction: column;
    padding: 20px;
  }

  .flex_btm_box {
    flex-direction: column;
  }

  .box_1 {
    width: 100%;
  }

  .image_wrapper {
    display: none;
  }

  .show_img {
   display: block !important;
   margin-top: 10%;
   margin-bottom: 10%;
  }

  .show_img img {
   width: 100% !important;
  }
}


.image_wrapper a {
   text-align: center;
}

.img_xdiv {
  width: 100%;
}
.img_xdiv img {
  margin-bottom: 20px;
}