.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.711);
}

.logo {
  display: flex;
  align-items: flex-end;
}

img.logo {
  width: 40px;
  border-radius: 10px;
  margin-right: 10px;
}

.nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav ul li {
  margin-left: 35px;
}

.active {
  color: #1498dd;
}

.nav ul li svg {
  font-size: 30px;
  margin-left: 40px;
}

.mobile_only {
  display: none;
}

@media (max-width: 700px) {
  .desktop_only {
    display: none;
  }
  .header {
    justify-content: space-between;
  }

  .mobile_only {
    display: block;
  }

  .mobile_only i {
    padding: 7px;
  }

  .nav ul li svg {
    font-size: 20px;
    margin: unset;
  }

  .nav ul {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
  }
}

.logo h3 {
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 3px solid #1498dd;
  cursor: pointer;
}

@media (max-width: 700px) {
  .logo h3 {
    font-size: 20px;
  }
}

.mobile_hamburger {
  display: none;
}

@media (max-width: 700px) {
  .mobile_hamburger {
    /* display: block; */
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .mobile_hamburger svg {
    font-size: 30px;
  }
}

.download_btn {
  padding: 16px 30px;
  background-color: #1498dd;
  color: white;
  border-radius: 30px;
}
