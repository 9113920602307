.main {
   display: flex;
   justify-content: space-between;
}

.left_container {
   flex-basis: 35%;
   padding: 20px;
   margin: 20px;
}

.right_container {
   flex-basis: 60%;
   padding: 20px;
   margin: 20px;
   background-color: rgb(33, 33, 33);
}


/* Form section */
.main h2 {
   margin-bottom: 15px;
}

.main p {
   color: #bfbfbf;
}
.form_wrapper {
   display: flex;
   margin-top: 20px;
   justify-content: space-between;
}

.input_wrapper {
   flex-basis: 45%;
}


.input_wrapper input {
   width: 100%;
   padding: 20px;
   background-color: unset;
   border: 1px solid #ddd;
   color: white;
   border-radius: 30px;
}
 label {
line-height: 2.4;
}


textarea {
   width: 100%;
   padding: 20px;
   background-color: unset;
   border: 1px solid #ddd;
   border-radius: 10px;
   height: 250px;
   color: white;
}

textarea::placeholder {
   color: #ddd;
}

.text_area_section {
   margin-top: 3rem;
}

.text_area_section button {
   padding: 14px 20px;
   background-color: #1498dd;
   color: white;
   border-radius: 20px;
   margin-top: 20px;
}


@media (max-width: 700px) {

   .main {
      flex-direction: column;
   }

   .form_wrapper {
      flex-direction: column;
   }

   .input_wrapper {
      margin-bottom: 2rem;
   }



}


.error {
   color: red !important;
   font-weight: bold;
   margin-top: 10px;
}