.section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 9vh;

  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, #1497dd30 100%);
  background: url("https://images.pexels.com/photos/2847906/pexels-photo-2847906.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2") no-repeat;
  background-size: cover;
  padding: 30px;
  background-attachment: fixed;
}

.noBg {
  background: unset;
}

.details {
  flex-basis: 50%;
}

@media (max-width: 700px) {
  .section {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .section img {
    width: 100%;
    margin-bottom: 30px;
  }
}

.name h1 {
  font-size: 3rem;
  font-weight: bolder;
  text-align: center
}

@media (max-width: 700px) {
  .name h1,
  .name h1:last-child {
    font-size: 1.5rem;
  }
}

.double_line div {
  width: 200px;
  height: 10px;
  background-color: white;
  border-radius: 20px;
}

.double_line div:last-child {
  margin-left: 6rem;
  background-color: hotpink;
}

.stack {
  margin: 40px 0px;
  text-align: center;
}

.stack p {
  line-height: 1.6;
  text-shadow: 0px 0px 10px gray;
}
.stack h1 {
  font-size: 25px;
  line-height: 2;
}

@media (max-width: 700px) {
  .stack {
    margin-top: 2rem;
  }
  .stack h1 {
    margin-top: 2rem !important;
    text-align: left;
    font-size: 15px;
    line-height: 0.1;
  }
}

.download_ {
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
}
.download_ img {
  width: 200px;
  height: 70px;
  border-radius: 20px;
  margin-right: 20px;
}

.left_image img {
  width: 250px;
}

@media (max-width: 700px) {
  .download_ img {
    width: 150px;
    height: auto;
    margin-right: 10px;
  }
}

.apply_btn button {
  padding: 10px 40px;
  /* background-color: white !important; */
  border: 2px solid white;
  margin: 20px 0px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
}

.rider_login_wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.813);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900;
}

.login_wrapper {
  width: 500px;
  background-color: black;
  padding: 20px;
  box-shadow: 9px 11px 10px #282828;
}

.login_wrapper form {
  margin: 10px 0px;
}

.login_wrapper form input {
  width: 50%;
  margin: 10px 0px;
  padding: 10px;
}

@media (max-width: 700px) {
  .rider_login_wrapper {
    width: 90%;
  }
}

.login_wrapper form input {
  width: 100%;
}

.login_wrapper h1 {
  margin-bottom: 5px;
}

.login_wrapper form input[type="submit"] {
  background-color: #1498dd;
  width: 25%;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.request_code_style {
  color: #1498dd;
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
}
