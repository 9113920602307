.footer {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
}

.about {
  flex-basis: 30%;
}

.about p {
  margin: 20px 0px
}

.about i {
  font-size: 25px;
  margin-right: 20px;
}

.about i:hover {
  color: #1498dd;
}

.contact_list {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

.contact_list i {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  background-color: #1498dd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  
}

.btn_txt {
  padding: 0px 30px;
}


@media (max-width: 700px) {

.footer {
  flex-direction: column;
}

.about {
  margin-bottom: 20px;
}

.contact {
  margin: 25px 0px;
}

.contact_list {
  margin-bottom: unset;
}

}