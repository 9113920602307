.container {
  position: fixed;
  top: 0px;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    #1497ddd8 0%,
    rgba(28, 28, 28, 1) 56%
  );
  z-index: 300;
  animation: slide_out 0.2s ease forwards;
}

@keyframes slide_out {
  from {
    left: -100%;
  }

  to {
    left: 0;
  }
}

.container .wrapper ul {
  display: flex;
  min-height: 100vh;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  line-height: 2;
}

.container .wrapper ul {
  font-size: 1.6rem;
}
.container .wrapper ul li {
    cursor: pointer;
}

.close_container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close_container svg {
  font-size: 3rem;
}



.active {
  color: #1498dd;
}