.conatiner {
   position: relative;
   width: 100%;
   height: 35vh;
   background-color: #1498dd94;
   background: url("https://images.pexels.com/photos/4467632/pexels-photo-4467632.jpeg?auto=compress&cs=tinysrgb&w=800") no-repeat;
   background-attachment: fixed;
   background-size: cover;
}
.wrapper {
   position: absolute;
   bottom: 0;
   left: 0;
   background-color: #1498dd94;
   width: 100%;
   height: 100%;
   display: flex;
   /* align-items: center; */
   justify-content: center;
   flex-direction: column;
   padding: 46px 30px;

}

.conatiner h1 {
   font-size: 50px;
   margin-bottom: 20px;
}

.download_ {
   margin-top: 20px;
   display: flex;
 }
 .download_ img{
   width: 200px;
   height: 70px;
   /* border-radius: 20px; */
   margin-right: 20px;
 }
 


@media (max-width: 700px) {

   .conatiner {
      height: 40vh;
    
   }

   .wrapper {
      display: flex;
      align-items: flex-start;
   }
   .conatiner h1 {
      text-align: center;
      font-size: 25px;
   }

   .conatiner p {
      color: #ffffff94;
      font-weight: bolder;
   }


   .download_ {
      justify-content: center;
   }
   .download_ img{
      width: 150px;
      height: auto;
      /* margin: 10px; */
    }

}

